<template>
  <div>
    <div>
      <div class="min-h-72 mr-3 d-flex w-100">
        <section class="mx-md-auto w-md-50 w-100 px-md-3 px-4 content-wrapper">
          <h5 class="d-block mb-4">Prestadores {{ providers.length }}</h5>
          <div class="mb-3">
            <span>Destaques</span>
          </div>
          <div
            class="text-center my-5"
            v-if="providers.length === 0 && !loading"
          >
            <img
              src="@/assets/images/svg/wait-validation.svg"
              height="200"
              class="d-block mx-auto mb-4 w-100"
              alt=""
            />
            <span>
              Estamos aguardando o inicio das validações <br />
              quando estiver tudo ok os prestadores começarão a ser rankeados
              aqui!
            </span>
          </div>
          <div v-for="(ranking, index) in providers" :key="index">
            <b-card
              class="mb-2"
              no-body
              @click="handleSelectCard(ranking)"
              :border-variant="ranking.winner ? 'warning' : 'light'"
              :class="ranking.winner ? 'shadow-sm mb-5' : ''"
            >
              <div
                class="d-flex align-items-center text-muted item pt-2 px-3 "
                v-if="ranking && ranking.provider"
              >
                <avatar
                  :name="ranking.provider.social_reason"
                  width="30px"
                  height="30px"
                  shadow
                  class="mr-2"
                />

                <strong class="w-100 mb-1 d-block">
                  {{ ranking.provider.social_reason }}
                </strong>
                
                <b-badge :variant="getStatus(ranking.status).variant" class="d-table">
                  {{ getStatus(ranking.status).label }}
                </b-badge>
              </div>
              <div
                class="pointer p-2 card-provider flex-row align-items-center"
              >
                <div class="text-dark ml-2 mr-4">
                  <strong class="d-flex align-items-center">
                    <Medal :ranking="index + 1" v-if="index <= 2" />
                  </strong>
                </div>

                <div
                  class="item d-flex w-100 align-items-center"
                  v-if="!is_mobile"
                >
                  <span class="mr-3">Apurado: </span>
                  <b-progress style="height: 8px" :max="100" class="w-100">
                    <b-progress-bar
                      :value="ranking.progress || 0"
                      variant="primary"
                    />
                  </b-progress>
                  <span class="ml-3">{{ ranking.progress || 0 }}%</span>
                </div>
                <div
                  class="my-2 text-muted item text-right pr-md-4 pr-2"
                  v-if="ranking.score"
                >
                  <strong>
                    {{ ranking.score ? ranking.score.toFixed(1) : 0 }}
                  </strong>
                  <span> pontos</span>
                </div>
                <b-badge v-if="ranking.winner">Vencedor !!!</b-badge>
              </div>
              <div
                class="d-flex w-100 align-items-center p-2 px-3"
                v-if="is_mobile"
              >
                <span class="mr-3">Apurado: </span>
                <b-progress style="height: 8px" :max="100" class="w-100">
                  <b-progress-bar
                    :value="ranking.progress || 0"
                    variant="primary"
                  />
                </b-progress>
                <span class="ml-3">{{ ranking.progress || 0 }}%</span>
              </div>
            </b-card>
            <div v-if="index === 2" class="mt-4 mb-2">
              <hr />
              <span>Outros colocados</span>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Medal from "@/pages/Homologation/Services/Modules/Financial/Medal";

export default {
  data() {
    return {
      loading: false,
      selectedProvider: {},
      crowns: ["gold", "silver", "bronze"],
      providers: [],
      homologation_id: "",
      service_id: "",
      current_step: "",
      enable_validation_button: "",
    };
  },

  components: {
    Medal,
  },

  computed: {
    ...mapGetters(["is_mobile", "user"]),
  },
  methods: {
    ...mapActions([
      "get_service_validation_admin",
      "get_service_validation_ranking",
      "get_service_scope",
      "show_toast"
    ]),
    sliceAvatar() {
      const length = this.serviceScope.notifications.length;
      if (length > 4) {
        return length - 4;
      }
      return 0;
    },
    getServiceValidation() {
      this.loading = true;
      this.get_service_validation_admin({ service_id: this.service_id }).then(
        ({ steps, current_step, enable_validation_button }) => {
          this.steps = steps;
          this.current_step = current_step;
          this.enable_validation_button = enable_validation_button;
        }
      );
      this.get_service_validation_ranking({ service_id: this.service_id }).then(
        ({ data }) => {
          this.providers = data.results || [];
          this.loading = false;
        }
      );
    },
    handleSelectCard(ranking) {
      this.selectedProvider = {};
      this.selectedProvider = ranking.provider;
      if (ranking.status == 0){
        this.show_toast({
          message: "O prestador ainda não iniciou a validação dos módulos.",
          type: "error",
        });
      } else {
        this.$router.push(`/homologation/${this.homologation_id}/service/${this.service_id}/${user.role}/result/${ranking.provider.id}/general`);
      }
    },
    getStatus(status = 0) {
      let variant;
      let label;
      switch (status) {
        case 0:
          variant = "info";
          label = "Não iniciado";
          break;
        case 1:
          variant = "secondary";
          label = "Em progresso";
          break;
        case 2:
          variant = "success";
          label = "Finalizado";
          break;
      }
      return {
        variant,
        label,
      };
    },
  },

  mounted() {
    this.service_id = this.$route.params.service_id;
    this.homologation_id = this.$route.params.homologation_id;
    this.getServiceValidation();
    this.selectedProvider = this.providers[0];
  },
};
</script>
